<template>
  <div style="padding:0 20px;">
    <div class="hint">
      <p style="text-indent: unset;">政策更新日期：2023年4月10号</p>
      <p style="text-indent: unset;">政策生效日期：2023年4月10号</p>
    </div>
    <p>武汉水凝网络科技有限公司（以下简称“我们”注册地址：湖北省武汉市江夏区大桥新区渔牧村文史馆旁侨亚国际广场12层19室（04）号）作为光恋App（以下简称“光恋”）的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们承诺严格遵守法律法规，并需要您在使用光恋及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</p>
    <p style="font-weight:bold;text-indent: unset;">本隐私政策将帮助你了解：</p>
    <p>我们会遵循隐私政策收集、使用你的信息，但不会仅因你同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。</p>
    <p>当你使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现业务功能或根据法律法规要求所必需的必要信息，你均可以拒绝提供且不影响其他功能或服务。详见<a href="#one"  style="color:blue;">个人信息收集清单</a>。</p>
    <p>如果你未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果你登录了帐号，我们会根据帐号信息实现信息推送。</p>
    <p>精确地理位置、摄像头（相机）、麦克风、相册（存储）、日历、活动记录权限，均不会默认开启，只有经过你的明示授权才会在为实现特定功能或服务时使用，你也可以撤回授权。<span style="font-weight:bold;">特别需要指出的是，即使经过你的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时收集你的信息。</span> <a href="#two" style="color:blue;">应用权限申请与使用情况说明。</a></p>
    <p>我们将审慎评估合作方使用信息的目的，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。 <a href="#three" style="color:blue;">第三方信息共享清单。</a></p>
    <div class="title">APP自启动关联启动的规则说明</div>
    <p>当您使用本APP的消息推送及消息提醒服务功能时，为了保证在您准时收到相关聊天信息，服务订单状态，最新活动咨询，我们需要使用自启动服务以便提醒能正常使用，我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息。</p>
    <p>推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
    <p>我们承诺严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：</p>
    <div id="one" class="title">一.个人信息收集清单</div>
    <div style="padding:0 20px;">
        <p>我们深知个人信息安全与隐私保护非常重要，本指引将帮助您直观、简明地了解我们是如何收集、处理使用个人信息以及如何保护您的隐私。</p>
        <p>我们会在向你提供服务过程中使用你主动提供的，以及经过你的授权通过自动化手段收集的下列信息：</p>
        <p>（1）个人身份信息</p>
        <p>（2）联系方式</p>
        <p>（3）地理位置</p>
        <p>（4）摄像头</p>
        <p>（5）麦克风</p>
        <p>（6）剪切板</p>
        <p>（7）设备信息（IMEI，IMSI，MAC地址，Android ID，OAID，ICCID，MEID，IDFA）</p>
        <p>（8）存储空间</p>
        <p>（9）软件安装列表</p>
        <p>（10）通讯录和短信</p>
        <div class="title">一．个人身份与联络方式</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">注册登录：</span>我们需要通过手机号码进行帐号注册、登录。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">实名认证：</span>我们需要通过身份证号码、姓名等信息完成特定场景下的实名制认证。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">安全保障：</span>在为保障安全等特定必要场景下，我们需要通过身份证信息相结合的方式来识别或认证您的身份。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">与您联系：</span>我们可能通过手机号码向您发送运营、推广、活动等信息，您可以拒绝或退订我们或运营方发送的上述信息。</p>
        <div class="title">二．地理位置</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>GPS等精确位置信息需要您额外单独授权，同时WLAN接入点、蓝牙、基站以及IP地址等信息也可以描述设备所在的大致位置。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>通常我们会使用位置信息推送相关信息或广告，帮助您在发布作品时展示定位位置。例如：在「动态圈」列表中，我们会使用IP位置确定您当前所在的城市，以为您搜索并展示同城相关的内容。</p>
        <div class="title">三．摄像头与麦克风</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>麦克风与摄像头均为敏感权限，我们只有在您需要发布作品、开直播等功能所需时开启。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>我们绝不会私自开启麦克风、摄像头窃取您的隐私或收集您日常聊天时的对话，更不会采用此种非法手段进行信息、广告推送。</p>
        <div class="title">四.剪切板</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>通过分享回流方式参与活动时，我们会对剪切板中是否有符合活动规则的信息在手机本地进行判断，不会将您写入剪切板的个人隐私信息回传。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">统计分析：</span>我们可能需要将应用来源渠道信息通过剪切板进行统计，在统计过程中我们不会将您写入剪切板的个人隐私信息回传。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>有些手机等终端设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是否收集剪切板信息，且不排除误报。因此，如果您发现类似提示，请与我们联系，以便我们定位问题。</p>
        <div class="title">五．设备信息</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>设备信息是指设备标识符（保护但不限于OAID、imei、IDFA），网络接入信息（包含但不限于IP、MAC地址等），手机型号，手机厂商，系统版本等等，设备信息在手机终端中可能被表述为“设备信息”，这不代表App会收集通讯内容或您主动输入的隐私信息。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用说明：</span>为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的设备的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、MEID、硬件序列号）、网络设备硬件地址（MAC地址）、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、设备传感器数据。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">其他说明：</span>为保证内容输入的有效性已经，我们对接了第三方友盟SDK数据统计以及友盟SDK消息推送通知，我们会在您需要的场合获取设备标识信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置信息），设备标识信息对于向未登录的用户推送其可能感兴趣的内容或广告具有不可替代的作用。设备信息也通常用在反作弊和判断帐号是否处于安全的环境中。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>我们不会主动获取您的imei信息，由于您的设备可能存在不支持或获取不到OAID的情况，我们将在询问您再获取您的设备imei信息。目前多数手机终端厂商已经支持对设备标识符的更改，以保障您对个人信息的控制权，具体撤回的途径和方式可以参见您所使用的终端设备说明。我们采用合并链路技术进行推送，当一个设备有多个APP的推送链路同时活跃时，我们会随机合并成一条链路，以达到为您省电省流量的目的，因此需获得应用列表信息，同时我们提供智能推送功能，通过该信息推荐更符合您需要的推送内容，减少无用推送信息对您用户的打扰。隐私权限政策链接：<a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></p>
        <div class="title">六．存储空间</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>存储空间权限是指提供写入外部储存功能，允许写入、删除存储空间及照片的权限。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>我们会申请存储权限，用于App或小程序写入、下载、保存、缓存、修改、删除图片、文件、崩溃日志等信息。</p>
        <div class="title">七．软件安装列表</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>在您微信登录，绑定微信，分享至微信时收集，详情请查阅《第三方信息共享清单》。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>使用微信SDK完成登录，分享，绑定操作时，优先判断本机是否安装微信App，在未安装微信App的情况下进行响应处理，提升用户体验。</p>
        <div class="title">八．通讯录与短信</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>在您登录时访问短信，在您添加好友时访问通讯录。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>（1）在您通过手机号验证码登录时，我们将读取您手机中的短信验证码，便于您一键输入验证码完成登录（2）在您添加好友时，您可匹配通讯录好友，向您进行好友推荐，您也可选择拒绝访问通讯录，或在设置中关闭通讯录好友推荐功能。</p>
      </div>
    <div id="two" class="title">二.应用权限申请与使用情况说明</div>
    <div style="padding:0 20px;">
        <p>1.为保障光恋、苃缘产品功能实现与安全稳定运行至目的，我们可能会申请或使用操作系统的相关权限；</p>
        <p>2.为保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；</p>
        <p>3.根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；</p>
        <p>4.请您知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方及关联方SDK，这些第三方也可能会申请或使用相关操作系统权限；</p>
        <p>5.在使用产品的过程中，您可能会使用第三方开发的H5页面或小程序，这些第三方开发的插件或小程序也可能因业务功能所必需而申请或使用相关操作系统权限；</p>
        <p>6.以下共同适用光恋、苃缘，如有单独适用的场景或目的将在下列列表说明。</p>
        <div class="title">Android应用权限列表</div>
        <p style="font-weight:bold; text-indent: unset;">一：拍摄CAMERA</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>使用拍摄照片和视频</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>上传动态拍摄照片，录制视频时使用</p>
        <p style="font-weight:bold; text-indent: unset;">二：获取应用账户GET_ACCOUNTS</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>获取设备上已安装的应用创建的账号，请您放心，该权限仅获取应用相关的信息，无法获取您的银行账号密码等个人敏感信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您通过第三方账号登录App</p>
        <p style="font-weight:bold; text-indent: unset;">三：访问精准定位ACCESS_FINE_LOCATION</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）获取精准地理位置信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">四：访问粗略位置ACCESS_COARSE_LOCATION</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>通过网络位置信息（例如基站和WLAN）获取大致地理位置信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障服务及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">五：录音RECORD_AUDIO</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>使用麦克风录制音频</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">六：读取电话状态READ_PHONE_STATE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>读取设备通话状态和识别码，识别手机设备ID，保证运营商网络免流服务，用于完成音视频、信息展示、账号登录、安全保障等主要功能</p>
        <p style="font-weight:bold; text-indent: unset;">七：读取外置存储器READ_EXTERNAL_STORAGE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供读取手机储存空间内数据的功能</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息，在本地记录崩溃日志信息（如有）、清理卸载残留等功能</p>
        <p style="font-weight:bold; text-indent: unset;">八：写入外置存储器WRITE_EXTERNAL_STORAGE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供写入外部储存功能</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志、卸载残留等信息</p>
        <div class="title">iOS 操作系统应用权限列表</div>
        <p style="font-weight:bold; text-indent: unset;">一．NSPhotoLibraryAddUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>向相册中添加内容</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息</p>
        <p style="font-weight:bold; text-indent: unset;">二．NSPhotoLibraryUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>读取相册中内容</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息等功能</p>
        <p style="font-weight:bold; text-indent: unset;">三．NSCameraUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>使用摄像头</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布、完成扫描二维码等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">四．NSLocationAlwaysUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>持续获取地理位置</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">五．NSLocationWhenInUseUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>仅App被使用时获取地理位置</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">六．NSLocalNetworkUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>请求访问本地网络</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>此App将可发现和连接到您所用网络上的设备</p>
        <p style="font-weight:bold; text-indent: unset;">七．NSMicrophoneUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>使用麦克风</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">八．NSUserTrackingUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>跨程序用户追踪</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于跨程序用户跟踪，允许后，提供的信息你可能会更感兴趣，请放心本次授权不会获取你在其他应用内的隐私；若拒绝，提供信息的相关性可能会降低</p>

    </div>
    <div id="three" class="title">三.第三方信息共享清单</div>
    <div style="padding:0 20px;">
        <p>1.为保障光恋、苃缘相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。</p>
        <p>2.我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。</p>
        <p>3.我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策或服务协议。</p>
        <p>4.请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。</p>
        <div class="title">第三方SDK目录</div>
        <p style="font-weight:bold; text-indent: unset;">阿里巴巴（淘宝）SDK||alipay SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、Android ID&iOS 如IDFV、IDFA、Serial、ICCID），MAC地址，IP 地址，WLAN接入点，获取SD卡数据，通过UTDevice获取IMSI</p>
        <p style="text-indent: unset;">使用目的：1.用于支付宝支付及支付过程中的安全风控2.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的设备的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、MEID、硬件序列号）、网络设备硬件地址（MAC地址）、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、设备传感器数据。</p>
        <p style="text-indent: unset;">数据类型：获取运行中的进程信息</p>
        <p style="text-indent: unset;">使用场景：在用户使用支付宝支付时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">支付宝（杭州）信息技术有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://render.alipay.com/p/f/fd-iwwyijeh/index.html" target="_blank">https://render.alipay.com/p/f/fd-iwwyijeh/index.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://docs.open.alipay.com/54" target="_blank">https://docs.open.alipay.com/54</a></p>
        <p style="font-weight:bold; text-indent: unset;">小米PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、MEID、OAID、Serial、uuid）、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在小米手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">北京小米移动软件有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://dev.mi.com/console/appservice/push.html" target="_blank">https://dev.mi.com/console/appservice/push.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://dev.mi.com/console/doc/detail?pId=1822" target="_blank">https://dev.mi.com/console/doc/detail?pId=1822</a></p>
        <p style="font-weight:bold; text-indent: unset;">魅族PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Serial）、MAC地址、IP地址、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在魅族手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">珠海市魅族通讯设备有限公司及其关联方（简称魅族）、与魅族建立合作的合作方公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.flyme.cn/open-web/views/push.html" target="_blank">https://open.flyme.cn/open-web/views/push.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.meizu.com/legal.html" target="_blank">https://www.meizu.com/legal.html</a></p>
        <p style="font-weight:bold; text-indent: unset;">华为PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：应用匿名标识（AAID），应用Token，应用列表</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在华为手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">华为软件技术有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.huawei.com/consumer/cn/doc/app/20213" target="_blank">https://developer.huawei.com/consumer/cn/doc/app/20213</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021" target="_blank">https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</a></p>
        <p style="font-weight:bold; text-indent: unset;">荣耀PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：应用匿名标识 (AAID)，应用Token</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在荣耀手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">荣耀终端有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.hihonor.com/cn/doc/guides/100226" target="_blank">https://developer.hihonor.com/cn/doc/guides/100226</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.hihonor.com/cn/privacy/privacy-policy/" target="_blank">https://www.hihonor.com/cn/privacy/privacy-policy/</a></p>
        <p style="font-weight:bold; text-indent: unset;">OPPO PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、SIM卡序列号、IMSI）、应用信息（如应用包名、版本号和运行状态）、网络信息（如IP或域名连接结果，当前网络类型）</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在OPPO手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">广东欢太科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.oppomobile.com/wiki/doc#id=10194" target="_blank">https://open.oppomobile.com/wiki/doc#id=10194</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://open.oppomobile.com/wiki/doc#id=10288" target="_blank">https://open.oppomobile.com/wiki/doc#id=10288</a></p>
        <p style="font-weight:bold; text-indent: unset;">VIVO PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备信息</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在VIVO手机终端推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">广东天宸网络科技有限公司及将来受让运营vivo开放平台的公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://dev.vivo.com.cn/documentCenter/doc/366" target="_blank">https://dev.vivo.com.cn/documentCenter/doc/366</a></p>
        <p style="font-weight:bold; text-indent: unset;">高德地图SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、OAID、Serial）、MAC地址、IP 地址、位置信息（如GPS）、WLAN接入点）</p>
        <p style="text-indent: unset;">使用目的：帮助用户发布信息时定位位置</p>
        <p style="text-indent: unset;">使用场景：用户使用定位相关功能时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">高德软件有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://lbs.amap.com/" target="_blank">https://lbs.amap.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://lbs.amap.com/pages/privacy/" target="_blank">https://lbs.amap.com/pages/privacy/</a></p>
        <p style="font-weight:bold; text-indent: unset;">友盟umengSDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、Android ID&iOS 如IDFV、IDFA、Serial），WLAN接入点，获取SD卡数据，获取设备序列号</p>
        <p style="text-indent: unset;">使用目的：1.推送信息2.产品安全保障功能：为了保障您的账号安全和系统安全，我们需要获取您的设备MAC地址、IMSI、Android ID、IP地址、硬件型号、操作系统版本号、唯一设备标识符（IMEI等）、网络设备硬件地址（MAC）、软件版本号、网络接入方式、类型、状态、网络质量数据、操作日志、硬件序列号、服务日志信息，收集运行中的进程，已安装应用列表等行为。</p>
        <p style="text-indent: unset;">数据类型：获取运行中的进程信息</p>
        <p style="text-indent: unset;">使用场景：在推送消息时使用</p>
        <p style="text-indent: unset;">特别说明：我们会在询问您之后为你开启推送功能，开启推送功能后，App将会自启动以保留您的登录状态从而接收推送消息，若您开启推送后，可在【我的】-【设置】-【消息通知设置】中将通知模式改为勿扰，那么App将不在自启动同时将不再向您推送消息</p>
        <p style="text-indent: unset;">推送场景说明：1：聊天消息，系统消息通知推送，2：订单状态变更通知推送，3：活动资讯通知推送</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">北京锐讯灵通科技有限公司、友盟同欣（北京）科技有限公司、浙江阿里巴巴云计算有限公司、北京缔元信互联网数据技术有限公司等</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.umeng.com/docs/147377/detail/210024" target="_blank">https://developer.umeng.com/docs/147377/detail/210024</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://developer.umeng.com/docs/147377/detail/209997" target="_blank">https://developer.umeng.com/docs/147377/detail/209997</a></p>
        <p style="font-weight:bold; text-indent: unset;">微信SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、Serial），MAC地址、WLAN接入点，获取SD卡数据，应用安装列表</p>
        <p style="text-indent: unset;">使用目的：支持微信登录和分享</p>
        <p style="text-indent: unset;">使用场景：在用户使用微信登录或者分享时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.weixin.qq.com/" target="_blank">https://open.weixin.qq.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy" target="_blank">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p style="font-weight:bold; text-indent: unset;">微信支付SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、Serial），MAC地址、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：支持微信支付</p>
        <p style="text-indent: unset;">使用场景：在用户使用微信支付时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.weixin.qq.com/" target="_blank">https://open.weixin.qq.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy" target="_blank">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p style="font-weight:bold; text-indent: unset;">网易易盾SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型: 设备信息 (IMEI、AndroidID、MAC)、地理位置信息、抽样的设备交互信息、应用列表、设备信息系统类型、Android版本、设备型号、产品代号、手机品牌、设备序列号、ROM编号、hostname、设备名称、硬件、ROM标签、IMEI、AndroidID、OAID、GAID、移动运营商、SIM卡运营商、手机制式、双络制式、无线IP地址、无线Mac地址、无线网络名称(SSID)、无线BSSID、代理配置、网络类型、当前时间、待机时间、运行时间、是否ROOT、应用包名、安装时间、应用签名 MD5、屏幕亮度、充电状态、电量、屏幕分辨率、字体列表HASH、CPU型号、GPU信息、内存大小、可用内存、存储空间大小、可用存储空间、基带版本、内核版本、应用版本号、设备ID、传感器列表、辅助功能列表、模拟器类型、安装作弊工具情况</p>
        <p style="text-indent: unset;">使用目的：安全风控，识别用户风险、设备环境风险</p>
        <p style="text-indent: unset;">使用场景：提高应用及合作伙伴服务的安全性，保证用户人身财产安全、预防钓鱼网站、欺诈、网络漏、计算机病毒、双络入侵等风险</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">杭州网易智企科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://dun.163.com/" target="_blank">https://dun.163.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://dun.13.com/clause/prvacy">https://dun.13.com/clause/prvacy</a></p>
        <p style="font-weight:bold; text-indent: unset;">移动智能终端补充设备标识体系统一调用SDK（移动安全联盟MSA）</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备信息 (IMEI、AndroidID、MAC)、设备信息系统类型、设备型号、、手机品牌、设备序列号、设备名称、IMEI、移动运营商、SIM卡运营商、手机制式、双络制式、设备信息（OAID）</p>
        <p style="text-indent: unset;">使用目的：生成设备标识信息</p>
        <p style="text-indent: unset;">使用场景：向设备用户进行推送消息</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">电信终端产业协会移动安全工作委员会</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="http://www.msa-alliance.cn/col.jsp?id=120" target="_blank">http://www.msa-alliance.cn/col.jsp?id=120</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="http://www.msa-alliance.cn/col.jsp?id=122" target="_blank">http://www.msa-alliance.cn/col.jsp?id=122</a></p>
        <p style="font-weight:bold; text-indent: unset;">腾讯Bugly SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：异常上报</p>
        <p style="text-indent: unset;">使用场景：在App异常闪退等情况下收集上报，协助快速发现并解决异常</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://bugly.qq.com/v2/index" target="_blank">https://bugly.qq.com/v2/index</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56" target="_blank">https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</a></p>
        <p style="font-weight:bold; text-indent: unset;">网易云信 SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：信令体系以及IM及时通讯</p>
        <p style="text-indent: unset;">数据类型：获取运行中的进程信息</p>
        <p style="text-indent: unset;">使用场景：实现私聊、群聊、聊天室等通讯功能</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">杭州网易质云科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://yunxin.163.com/im" target="_blank">https://yunxin.163.com/im</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://yunxin.163.com/clauses?serviceType=3 " target="_blank">https://yunxin.163.com/clauses?serviceType=3 </a></p>
        <p style="font-weight:bold; text-indent: unset;">agora 声网 SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：实现直播间推拉流</p>
        <p style="text-indent: unset;">使用场景：互动直播，语音连麦 </p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">上海兆言网络科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://www.agora.io/cn/?utm_campaign=pinzhuan&utm_medium=cpc&utm_content=pinzhuan&utm_term=pinzhuan" target="_blank">https://www.agora.io/cn/?utm_campaign=pinzhuan&utm_medium=cpc&utm_content=pinzhuan&utm_term=pinzhuan</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.agora.io/cn/privacy-policy" target="_blank">https://www.agora.io/cn/privacy-policy</a></p>

        <p style="font-weight:bold; text-indent: unset;">360加固SDK (安卓版本) </p>
        <p style="text-indent: unset;">涉及的个人信息类型：无</p>
        <p style="text-indent: unset;">使用目的：用于检测App故障和诊断,App安全加固保护,盗版检测服务,威胁环境检测服务,以便帮助用户快速解决异常情况 </p>
        <p style="text-indent: unset;">使用场景：APK加固</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">北京鸿享技术服务有限公司</span></p>
        <p style="text-indent: unset;">授权方式：由App开发者决定,当终端用户同意向开发者App授权该权限时开启</p>
        <p style="text-indent: unset;">官网链接：<a href="https://jiagu.360.cn" target="_blank">https://jiagu.360.cn</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://jiagu.360.cn/#/global/help/295" target="_blank">https://jiagu.360.cn/#/global/help/295</a></p>

        <p style="font-weight:bold; text-indent: unset;">openinstall SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：获取粘贴板信息，获取设备序列号</p>
        <p style="text-indent: unset;">使用目的：1.在你分享或接收被分享的信息、参加活动等情形下，我们需要在本地访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。我们仅在本地识别出剪切板内容属于跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，我们不会上传你剪切板的其他信息至我们的服务器。另外，我们可能需要读取你的手机相册以便于你分享或接收被分享的视频和图片2.在您注册/登录账户时，您需要提供 手机号码及验证码 ，使用App服务时，您需提供 手机号码 作为账户登录名，登录时我们还需要收集您的 硬件序列号、唯一设备识别码（android_id、Mac地址、IMEI、IMSI,SIM序列号） 以验证账号及密码并完成登录；如您拒绝提供 手机号码、验证码及密码 个人信息，则可能无法成功注册/登录App账户。</p>
        <p style="text-indent: unset;">使用场景：用户登录，注册，激活时进行转化数据分析并排重</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市分秒网络科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK收集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://www.openinstall.io/" target="_blank">https://www.openinstall.io/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.openinstall.io/privacy.html" target="_blank">https://www.openinstall.io/privacy.html</a></p>
    </div>
    <div class="title">一、我们可能收集的信息</div>
    <p style="text-indent: unset;">（一）与个人身份无关的信息：</p>
    <p>当您使用我们的服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用服务的每个用户的来源途径、浏览器软件等。</p>
    <p style="text-indent: unset;">（二）有关个人身份的信息：</p>
    <p>当您使用服务时，我们可能收集和汇总或要求您提供有关个人身份的信息：例如生日、性别、兴趣爱好、个人电话号码；网络身份标识信息（包括系统账号、IP地址、电子邮箱地址）；通讯信息；设备信息（包括设备型号、设备MAC地址、操作系统类型）；软件列表唯一设备识别码（如IMEI/androidID/IDFA/OPENUDID/GUID等在内的描述个人常用设备基本情况的信息）；位置信息（包括精准定位信息、经纬度等）。</p>
    <p>我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用服务。我们的目标是向所有的互联网用户提供安全、有趣及有教益的使用体验。而这些信息有助于我们实现这一目标。</p>
    <div class="title">二、我们如何收集和使用信息</div>
    <p style="text-indent: unset;">（一）我们将通过以下途径收集和获得您的信息：</p>
    <p>1、您提供的信息。 例如：</p>
    <p>（1）您在注册服务帐号或使用服务时，向我们提供的信息；</p>
    <p>（2）您通过我们的服务向其他方提供的共享信息，以及您使用服务时所储存的信息。</p>
    <p>2、其他方分享的您的信息。亦即其他方使用我们的服务时所提供有关您的共享信息。</p>
    <p>3、我们获取的您的信息。您在使用我们的服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息等。</p>
    <p style="text-indent: unset;">（二）我们会出于以下目的，收集和使用您的信息：</p>
    <p>1、协助您完成注册</p>
    <p>为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的用户及提供上述信息。</p>
    <p>2、向您提供商品或服务</p>
    <p>我们所收集和使用的信息是为您提供服务的必要条件，如缺少相关信息，我们将无法为您提供服务的核心内容，例如：</p>
    <p>（1）在部分服务项目中，为便于向您交付商品或服务，您需提供联系电话、支付状态等信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过我们的服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。</p>
    <p>（2）为展示您账户的订单信息，我们会收集您在使用我们的服务过程中产生的订单信息用于向您展示及便于您对订单进行管理；</p>
    <p>（3）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
    <p>（4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构与交易进度相关的您的交易、支付信。</p>
    <p>3、向您推送消息</p>
    <p>（1）为您展示和推送商品或服务。我们可能使用您的信息，您的设备信息、订单信息，并通过即时通讯服务向您发送交易成功信息，提供或推广我们或第三方的如下商品和服务：</p>
    <p>我们的商品和服务，包括但不限于：即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、支付服务、位置和地图服务、应用软件和服务、数据管理软件和服务及其他社交媒体、娱乐、电子商务、资讯及通讯软件和服务（“互联网服务”）以及我们认为可能与您相关的其他商品和服务。</p>
    <p>（2）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。</p>
    <p>如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如在移动端设备中进行设置，不再接收我们推送的消息等；但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。</p>
    <!-- <p>4、为您提供安全保障</p>
    <p>为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认证。</p>
    <p>除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p> -->
    <p>4、改进我们的服务</p>
    <p>我们可能将通过某一项服务所收集的信息，用于我们的其他服务。例如，在您使用某一项服务时所收集的您的信息，可能在另一项服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的信息用于软件更新。</p>
    <p>您了解并同意，在收集您的信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析。</p>
    <p>如果我们将您的信息用于本《隐私政策》中未载明的其他用途，会事先征求您同意。</p>
    <p>5、征得授权同意的例外</p>
    <p>根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</p>
    <p>（1）与国家安全、国防安全有关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
    <p>（5）所收集的信息是您自行向社会公众公开的；</p>
    <p>（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>（7）根据您的要求签订合同所必需的；</p>
    <p>（8）用于维护服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
    <p>（9）为合法的新闻报道所必需的；</p>
    <p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；</p>
    <p>（11）法律法规规定的其他情形。</p>
    <div class="title">三、我们可能分享、转让或披露的信息</div>
    <p style="text-indent: unset;">（一）分享</p>
    <p>除以下情形外，未经您同意，我们不会与本公司之外的任何第三方分享您的信息：</p>
    <p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：微信分享；</p>
    <p>2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：系统消息推送服务；</p>
    <p>3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；</p>
    <p>4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；</p>
    <p>5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。</p>
    <p>6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。</p>
    <p>7、应您合法需求，协助处理您与他人的纠纷或争议；</p>
    <p>8、应您的监护人合法要求而提供您的信息；</p>
    <p>9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
    <p>10、基于学术研究而提供；</p>
    <p>11、基于符合法律法规的社会公共利益而提供。</p>
    <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理信息。</p>
    <p style="text-indent: unset;">（二）转让</p>
    <p>1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
    <p>2、在获得您的明确同意后，我们会向其他方转让您的信息。</p>
    <p style="text-indent: unset;">（三）披露</p>
    <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：</p>
    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
    <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
    <div class="title">四、我们如何保留、储存和保护信息</div>
    <p>我们仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的信息。</p>
    <p>我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。</p>
    <p>我们将采取以下手段保护您的信息：</p>
    <p style="text-indent: unset;">（一）数据安全技术措施</p>
    <p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。</p>
    <p style="text-indent: unset;">（二）我们为保护信息采取的其他安全措施</p>
    <p>我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。</p>

    <p>我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
    <p>加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</p>
    <p style="text-indent: unset;">（三）我们仅允许有必要知晓这些信息的员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我方的合作关系。</p>
    <p style="text-indent: unset;">（四）我们会采取一切合理可行的措施，确保未收集无关的信息。</p>
    <p style="text-indent: unset;">（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</p>
    <p style="text-indent: unset;">（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
    <p style="text-indent: unset;">（七）安全事件处置</p>
    <p>在通过我们的服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。</p>
    <p>为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
    <p>在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</p>
    <p>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>
    <div class="title">五、如何管理您的信息</div>
    <p style="text-indent: unset;">（一）访问、更新和删除</p>
    <p>我们鼓励您更新和修改您的信息以使其更准确有效。您能通过我们的服务访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段，尽可能保证您可以访问、更新和更正自己的信息或使用我们的服务时提供的其他信息。</p>
    <p>在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。</p>
    <!-- <p style="text-indent: unset;">（二）公开与分享</p>
    <p>我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。</p> -->
    <p style="text-indent: unset;">（二）注销</p>
    <p>在符合单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项服务帐号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。</p>
    <p>你可以在光恋“设置”中申请帐号注销。在你注销帐号前，我们将验证你的个人身份、安全状态、设备信息等。有关注销的流程和内容，请参见《<router-link to="/logout" style="color:blue;">帐号注销协议</router-link>》。我们将会在收到你的申请后及时进行处理，请你知悉并理解，注销帐号的行为是不可逆的行为，当你注销帐号后，我们将删除有关你的相关信息或进行匿名化处理，但法律法规及相关国家标准另有规定的除外。</p>
    <p style="text-indent: unset;">（三）改变您授权同意的范围</p>
    <p>您总是可以选择是否披露信息。有些信息是使用服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。</p>
    <p>当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。</p>
    <p style="text-indent: unset;">（四）有关敏感信息的提示</p>
    <p>某些信息因其特殊性可能被认为是敏感信息，例如您的种族、宗教、个人健康和医疗信息等，以及身份证明文件、个人生物识别信息、财产信息、行踪轨迹、未成年人的信息等。</p>
    <p>请注意，您在服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用服务披露您的敏感信息。</p>
    <p>您同意您的敏感信息按本《隐私政策》所述的目的和方式来处理。</p>
    <div class="title">六、第三方服务</div>
    <p>本服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：</p>
    <p>（1）您可利用“分享”键将某些内容分享到其他服务，或您可利用第三方服务登录我们的服务；</p>
    <p>（2）我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；</p>
    <p>（3）其他接入第三方服务的情形。</p>
    <p>该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。</p>
    <div class="title">七、年龄限制</div>
    <p>我们建议：任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。</p>
    <p>我们鼓励父母或监护人指导未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。</p>
    <div class="title">八、通知和修订</div>
    <p>我们可能适时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您可以选择停止使用我们的服务；在该种情况下，如您仍然继续使用我们的服务的，即表示同意受经修订的本《隐私政策》的约束。</p>
    <p>任何修改都会将您的满意度置于首位。我们鼓励您在每次使用我们的服务时都查阅我们的隐私政策。</p>
    <p>我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</p>
    <div class="title">九、联系我们</div>
    <p>如果您对个人信息保护问题有投诉、建议、疑问，您可通过以下信息联系我们：</p>
    <p>联系人：曹先生</p>
    <p>联系电话：18915711107</p>
    <p>地址：湖北省武汉市江夏区大桥新区渔牧村文史馆旁侨亚国际广场12层19室（04）号  法务部</p>
    <p style="font-weight:bold;">最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</p>
  </div>
</template>

<script>

export default {
  name: 'privacy',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
.hint{
  margin: 30px 0 50px;
}
.hint p{
  font-size: 32px;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
}
</style>
